import './Contact.css';
import NavigationBar from '../navbar/NavigationBar';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

function Contact() {
    const formNameRef = useRef();
    const formEmailRef = useRef();
    const formMessageRef = useRef();
    const formCCRef = useRef();
    const [ccAlert, setCCAlert] = useState(false);
    const [apiAlert, setAPIAlert] = useState(false);
    const [apiSuccess, setAPISuccess] = useState(false);
    const [apiWaiting, setAPIWaiting] = useState(false);
    const [responseValue, setResponseValue] = useState();
    const [emailPlaceholder, setEmailPlaceholder] = useState('Enter email (optional)');

    const handleSubmit = (event) => {
        event.preventDefault();

        let canSend = true;
        if (formCCRef.current.checked) {
            if (formEmailRef.current.value === '') {
                setCCAlert(true);
                canSend = false;
            } else {
                setCCAlert(false);
            }
        }

        if (canSend) {
            setAPIWaiting(true);

            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries());

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataObj)
            };

            fetch(process.env.REACT_APP_CONTACT_API_URL, requestOptions)
                .then(response => {
                    if (response.ok) {
                        setAPISuccess(true);
                        handleReset();
                    } else {
                        setAPIAlert(true);
                        if (response.statusText) {
                            setResponseValue(response.status + ' - ' + response.statusText);
                        } else {
                            // In local, statusText return Forbidden, but in prod, it returns undefined
                            response.text().then(res => setResponseValue(res));
                        }
                    }
                    setAPIWaiting(false);
                })
                .catch(error => {
                    setAPIAlert(true);
                    setAPIWaiting(false);
                    console.log(error)
                    setResponseValue('Failed to fetch... perhaps backend serivce is down.');
                });
        }
    }

    const handleReset = (event) => {
        event?.preventDefault();
        formNameRef.current.value = "";
        formEmailRef.current.value = "";
        formMessageRef.current.value = "";
        formCCRef.current.checked = false;
        setCCAlert(false);
        setAPIAlert(false);
    }

    const checkboxOnClick = (event) => {
        if (event.target.checked) {
            setEmailPlaceholder('Enter email');
        } else {
            setEmailPlaceholder('Enter email (optional)');
        }
    }

    const leaves = Array.from({ length: 20 }, (_, i) => i);

    return (
        <>
            <div className="contact-bg">
                <NavigationBar></NavigationBar>
                {leaves.map((leaf, index) => (
                    <div
                        key={index}
                        className="leaf"
                        style={{
                            '--fall-duration': `${Math.random() * 5 + 5}s`,
                            '--fall-left': `${Math.random() * 100}vw`
                        }}
                    />
                ))}
                {/* <div className="leaf">
                    <div><img src="http://www.pngmart.com/files/1/Fall-Autumn-Leaves-Transparent-PNG.png" height="75px" width="75px"></img></div>
                    <div><img src="http://www.pngmart.com/files/1/Autumn-Fall-Leaves-Pictures-Collage-PNG.png" height="75px" width="75px"></img></div>
                    <div><img src="http://www.pngmart.com/files/1/Autumn-Fall-Leaves-Clip-Art-PNG.png" height="75px" width="75px" ></img></div>
                    <div><img src="https://www.pngmart.com/files/4/Leaf-PNG-Transparent-Picture.png" height="75px" width="75px"></img></div>
                    <div><img src="http://www.pngmart.com/files/1/Transparent-Autumn-Leaves-Falling-PNG.png" height="75px" width="75px"></img></div>
                    <div>
                        <div>
                            <img src="http://www.pngmart.com/files/1/Realistic-Autumn-Fall-Leaves-PNG.png" height="75px" width="75px"></img>
                        </div>
                    </div>
                </div>
                <div className="leaf-second">
                    <div>
                        <div>
                            <img src="http://www.pngmart.com/files/1/Fall-Autumn-Leaves-Transparent-PNG.png" height="75px" width="75px"></img>
                        </div>
                    </div>
                </div> */}
                <Container style={{ marginTop: "1rem" }}>
                    {/* <Container className="my-auto"> */}
                    {
                        ccAlert &&
                        <Alert variant="danger" onClose={() => setCCAlert(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Something's not right...</Alert.Heading>
                            {
                                ccAlert &&
                                <p>
                                    Since you have marked checkbox for a carbon copy, email address is now required.
                                </p>
                            }
                        </Alert>
                    }
                    {
                        apiAlert &&
                        <Alert variant="danger" onClose={() => setAPIAlert(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Something's not right...</Alert.Heading>
                            {
                                apiAlert &&
                                <>
                                    <p>
                                        {responseValue}
                                    </p>
                                    <p>
                                        Please try again later.
                                    </p>
                                </>
                            }
                        </Alert>
                    }
                    {
                        apiSuccess &&
                        <Alert variant="success" onClose={() => setAPISuccess(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Thanks for your message!</Alert.Heading>
                            <p>
                                Your message has been received. I appreciate you filling this form out!
                            </p>
                            <p>
                                Click <a href="/">here</a> to go back to home page.
                            </p>
                        </Alert>
                    }
                    <div className="contact-form-box">
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        disabled={apiWaiting}
                                        required
                                        pattern="[A-Za-z]{2,}"
                                        type="text"
                                        name="formName"
                                        ref={formNameRef}
                                        placeholder="Enter your name (2 or more letters)" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        disabled={apiWaiting}
                                        type="email"
                                        name="formEmail"
                                        ref={formEmailRef}
                                        placeholder={emailPlaceholder} />
                                    {
                                        /* <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                        </Form.Text> */
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicTextArea">
                                    <Form.Label>Comments / Questions</Form.Label>
                                    <Form.Control
                                        disabled={apiWaiting}
                                        required
                                        as="textarea"
                                        rows={5}
                                        name="formMessage"
                                        ref={formMessageRef} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check
                                        disabled={apiWaiting}
                                        type="checkbox"
                                        name="formCC"
                                        // isInvalid={value.current.checked === true}
                                        ref={formCCRef}
                                        onClick={checkboxOnClick}
                                        label="Send me a carbon copy" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="secondary" type="button" disabled={apiWaiting} onClick={handleReset}>
                                        Reset
                                    </Button>
                                    {' '}
                                    {
                                        !apiWaiting &&
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    }
                                    {
                                        apiWaiting &&
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Submitting...
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Contact;