export { getLastUpdatedByRelative, getLastUpdatedByAbsolute };

function getLastUpdatedByRelative(epoch) {
    var result = 'Last updated: ';

    let modifiedTime = new Date(epoch * 1000);
    let diff = new Date().getTime() - modifiedTime.getTime();

    let diffInSeconds = diff / 1000;
    let diffInMinutes = diffInSeconds / 60;
    if (diffInMinutes >= 1) {
        let diffInHours = diffInMinutes / 60;
        if (diffInHours >= 1) {
            let diffInDays = diffInHours / 24;
            if (diffInDays >= 1) {
                let diffInWeeks = diffInDays / 7;
                if (diffInWeeks >= 1) {
                    result += Math.floor(diffInWeeks) + ' week(s) ago';
                } else {
                    result += Math.floor(diffInDays) + ' day(s) ago';
                }
            } else {
                result += Math.floor(diffInHours) + ' hour(s) ago';
            }
        } else {
            result += Math.floor(diffInMinutes) + ' minute(s) ago';
        }
    } else {
        result += Math.floor(diffInSeconds) + ' second(s) ago';
    }

    return result;
}

function getLastUpdatedByAbsolute(epoch) {
    return new Date(epoch * 1000).toDateString();
}