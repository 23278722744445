import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ConfirmPopup(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.handleCallBack}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleCallBack}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={props.handleProceed}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { ConfirmPopup };