import './About.css';
import { useState } from 'react';
import NavigationBar from '../navbar/NavigationBar';
import Container from 'react-bootstrap/Container';
import { ReactMarkdownWithPlugins } from "./../shared/ReactMarkdownWithPlugins.js";

function About() {
    const [aboutContent, setAboutContent] = useState(null);

    fetch(process.env.REACT_APP_ABOUT_CONTENT_API_URL)
        .then(response => {
            if (response.ok) {
                response.text().then(message => setAboutContent(message));
            } else {
                response.text().then(message => {
                    if (message) {
                        console.log(message);
                    }
                });
            }
        })
        .catch(error => {
            console.log(error);
        });

    return (
        <>
            <div className="about-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <div className='text-center' style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        <iframe
                            src="https://www.youtube.com/embed/buTr-t1vHVQ"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Inspiring & Uplifting Background Music"
                        />
                    </div>

                    <div className="text-center about-title-font">
                        <h1>About the author</h1>
                    </div>
                    <div className="center-content">
                        <div style={{ marginTop: "3rem", maxWidth: "500px" }}>
                            <p>
                                I am a <b>software engineer</b> with decades of IT experiences, having worked on various projects involving different technologies, languages and platforms.
                                I enjoy solving complex problems and creating <b>innovative</b> solutions.
                                I am always <b>eager to learn</b> new skills (especially around <b>AI</b>) and keep up with the latest trends in the industry.
                                I believe that software engineering is not only a profession, but also a <b>passion</b>.
                            </p>
                            <p>
                                Below is one of my LinkedIn blogs, where I shared other aspect of learning!
                            </p>
                            <iframe
                                src="https://www.linkedin.com/embed/feed/update/urn:li:share:7128772670686425089"
                                width={`100%`}
                                height={500}
                                title='My LinkedIn Blog'
                            />
                        </div>
                    </div>

                    <br />
                    <br />

                    <div className="text-center about-title-font">
                        <h1>About the app</h1>
                    </div>
                    <div style={{ marginTop: "3rem" }}>
                        {
                            aboutContent &&
                            <ReactMarkdownWithPlugins content={aboutContent}></ReactMarkdownWithPlugins>
                        }
                        {
                            !aboutContent &&
                            <>
                                <div className="text-center">
                                    <div className='text-muted'><em>Loading the content from backend...</em></div>
                                    <div className="lds-ellipsis">
                                        <div>
                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Container>
                <div className='about-footer'>
                    <div className="text-muted">
                        <div style={{ color: "white" }}>
                            <a style={{ textDecoration: "none", color: "white" }} href="/">Home</a> | <a style={{ textDecoration: "none", color: "white" }} href="/blogs">Blogs</a> | <a style={{ textDecoration: "none", color: "white" }} href="/contact">Contact</a>
                        </div>
                        <div style={{ color: "white" }}>&copy; 2023 JustinKim.dev</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;