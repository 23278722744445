import './OpenAI.css';
import { useRef, useState } from 'react';
import NavigationBar from "../../navbar/NavigationBar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function OpenAI() {

    const [apiAlert, setAPIAlert] = useState(false);
    const [apiAlertMessage, setAPIAlertMessage] = useState(null);
    const [apiSuccess, setAPISuccess] = useState(false);
    const [apiResponseMessage, setAPIResponseMessage] = useState(null);
    const [apiWaiting, setAPIWaiting] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imageChoiceVariation, setImageChoiceVariation] = useState(false);
    const [inputType, setInputType] = useState(false);

    // API Token
    const formAPITokenRef = useRef();

    // Generate
    const formImgDescRef = useRef();
    const formNumImgRef = useRef();
    const formImgSizeRef = useRef();

    // Edit
    const formNumImgEditRef = useRef();
    const formImgEditSizeRef = useRef();

    const formImgChoiceEditRef = useRef();
    const formImgChoiceVariationRef = useRef();

    const formInputChoiceFileRef = useRef();
    const formInputChoiceURLRef = useRef();

    const formImgEditDescRef = useRef();
    const formImgFileRef = useRef();
    const formImgURLRef = useRef();

    const handleReset = (event) => {
        event?.preventDefault();
        formImgDescRef.current.value = "";
        formNumImgRef.current.value = "";
        formImgSizeRef.current.value = "1024x1024";

        if (formImgEditDescRef && formImgEditDescRef.current) {
            formImgEditDescRef.current.value = "";
        }
        if (formImgFileRef && formImgFileRef.current) {
            formImgFileRef.current.value = "";
        }
        if (formImgURLRef && formImgURLRef.current) {
            formImgURLRef.current.value = "";
        }

        formNumImgEditRef.current.value = "";
        formImgEditSizeRef.current.value = "1024x1024";
        formImgChoiceEditRef.current.checked = true;
        formImgChoiceVariationRef.current.checked = false;
        setImageChoiceVariation(false);

        formInputChoiceFileRef.current.checked = true;
        formInputChoiceURLRef.current.checked = false;
        setInputType(false);

        setAPIAlert(false);
        setAPIAlertMessage(null);
        setAPISuccess(false);
        setAPIResponseMessage(null);
    }

    const handleImgGen = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target),
            formDataObj = Object.fromEntries(formData.entries());

        if (formDataObj && formDataObj.formNumImg) {
            if (formDataObj.formNumImg <= 0) {
                setAPIAlertMessage('\'Number of Images\' should be greater than 0');
                setAPIAlert(true);
                setAPISuccess(false);
            } else if (formDataObj.formNumImg > 10) {
                setAPIAlertMessage('\'Number of Images\' should be less than or equal to 10');
                setAPIAlert(true);
                setAPISuccess(false);
            } else {
                setAPIAlert(false);
                setAPIAlertMessage(null);
                setAPIWaiting(true);
                setAPISuccess(false);
                setAPIResponseMessage(null);

                formDataObj.formAPIToken = formAPITokenRef.current.value;
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formDataObj)
                };

                fetch(process.env.REACT_APP_PROJ_OPENAI_URL + '?option=gen', requestOptions)
                    .then(response => {
                        if (response.ok) {
                            response.text().then(value => {
                                setAPISuccess(true);

                                let parsed = JSON.parse(value);
                                setAPIResponseMessage(parsed);
                            });
                        } else if (response.status === 404 || response.status === 401) {
                            setAPIAlert(true);
                            setAPIAlertMessage('Looks like you need to login first.');
                        } else {
                            setAPIAlert(true);
                            response.text().then(value => {
                                setAPIAlertMessage(value);
                            })
                        }
                        setAPIWaiting(false);
                    })
                    .catch(error => {
                        setAPIAlertMessage(error.toString());
                        setAPIAlert(true);
                        setAPIWaiting(false);
                    });
            }
        }
    }

    const handleImgFile = (event) => {
        let files = event.target.files;
        if (files && files[0]) {
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    setImageFile(reader.result);
                },
                false
            );
            reader.readAsDataURL(files[0]);
        }
    }

    const handleImgEdit = (event) => {
        event.preventDefault();

        setAPIAlert(false);
        setAPIAlertMessage(null);
        setAPISuccess(false);
        setAPIResponseMessage(null);

        let jsonBody = {
            formAPIToken: formAPITokenRef.current.value,
            image: imageFile,
            imageURL: formImgURLRef.current.value,
            imageName: formImgFileRef && formImgFileRef.current ? formImgFileRef.current.value : null,
            imageSize: formImgEditSizeRef.current.value,
            imageNumber: formNumImgEditRef.current.value,
            imageChoiceEdit: formImgChoiceEditRef.current.checked,
            imageChoiceVariation: formImgChoiceVariationRef.current.checked,
            prompt: formImgEditDescRef.current ? formImgEditDescRef.current.value : ''
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonBody)
        };

        setAPIWaiting(true);
        fetch(process.env.REACT_APP_PROJ_OPENAI_URL + '?option=edit', requestOptions)
            .then(response => {
                if (response.ok) {
                    response.text().then(value => {
                        try {
                            let parsed = JSON.parse(value);
                            setAPIResponseMessage(parsed)
                            setAPISuccess(true);
                        } catch (ex) {
                            setAPIAlert(true);
                            setAPIAlertMessage(ex.toString());
                        }
                    });
                } else if (response.status === 404 || response.status === 401) {
                    setAPIAlert(true);
                    setAPIAlertMessage('Looks like you need to login first.');
                } else {
                    setAPIAlert(true);
                    response.text().then(value => {
                        setAPIAlertMessage(value.toString());
                    });
                }
                setAPIWaiting(false);
            })
            .catch(error => {
                setAPIAlertMessage(error.toString());
                setAPIAlert(true);
                setAPIWaiting(false);
            });
    }

    return (
        <>
            <div className="projects-openai-bg">
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "6rem" }}>
                    {
                        apiAlert &&
                        <Alert variant="danger" onClose={() => setAPIAlert(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Oh snap! Something's not right...</Alert.Heading>
                            {
                                apiAlert &&
                                <>
                                    {
                                        !apiAlertMessage &&
                                        <>
                                            <p>
                                                By the way, you need to login first if you want to use this service.
                                            </p>
                                            <p>
                                                If you still see this message after logging in, please feel free to send me a message using Contact form!
                                            </p>
                                        </>
                                    }
                                    {
                                        apiAlertMessage &&
                                        <>
                                            <p>
                                                {apiAlertMessage}
                                            </p>
                                        </>
                                    }
                                </>
                            }
                        </Alert>
                    }
                    {
                        apiSuccess &&
                        <Alert variant="success" onClose={() => setAPISuccess(false)} dismissible style={{ borderRadius: '20px' }}>
                            <Alert.Heading>Success!</Alert.Heading>
                            <p>Scroll down to view the image(s)!</p>
                        </Alert>
                    }
                    <Form.Group
                        style={{ backgroundColor: `rgba(255, 255, 255, 0.25)`, padding: "10px", borderRadius: "10px" }}
                        className="mb-3"
                        controlId="formAPIToken">
                        <Form.Label>ChatGPT Token</Form.Label>
                        <Form.Control disabled={apiWaiting}
                            name="formAPIToken"
                            ref={formAPITokenRef}
                            required
                            type="text" />
                        <Form.Text className="text-muted">
                            You can signup <a rel="noreferrer" target="_blank" href="https://platform.openai.com/account/api-keys">here</a> for a token for limited time usage!
                        </Form.Text>
                    </Form.Group>
                    <Tabs
                        defaultActiveKey="imgGen"
                        className="mb-3">
                        <Tab eventKey="imgGen" title="Generate Image">
                            <Form
                                onSubmit={handleImgGen}
                                style={{ background: "white", opacity: "85%", padding: "10px", borderRadius: "10px" }}>
                                <Form.Group className="mb-3" controlId="formImgDesc">
                                    <Form.Label>Image Description</Form.Label>
                                    <Form.Control disabled={apiWaiting} name="formImgDesc" ref={formImgDescRef} required type="text" />
                                    <Form.Text className="text-muted">
                                        e.g. "A cute baby sea otter"
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formNumImg">
                                    <Form.Label>Number of Images</Form.Label>
                                    <Form.Control
                                        disabled={apiWaiting}
                                        name="formNumImg"
                                        ref={formNumImgRef}
                                        required
                                        type="number" />
                                    <Form.Text className="text-muted">
                                        Must be between 1 and 10.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formImgSize">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Select name="formImgSize" disabled={apiWaiting} ref={formImgSizeRef} required aria-label="Default select size">
                                        <option value="1024x1024">1024 x 1024</option>
                                        <option value="512x512">512 x 512</option>
                                        <option value="256x256">256 x 256</option>
                                    </Form.Select>
                                </Form.Group>

                                <Button className="me-3" variant="secondary" onClick={handleReset} disabled={apiWaiting}>
                                    Reset
                                </Button>
                                {
                                    !apiWaiting &&
                                    <Button variant="primary" type="submit">
                                        Generate
                                    </Button>
                                }
                                {
                                    apiWaiting &&
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Generating...
                                    </Button>
                                }
                            </Form>
                        </Tab>
                        <Tab eventKey="imgEdit" title="Edit Image">
                            <Form
                                onSubmit={handleImgEdit}
                                style={{ background: "white", opacity: "85%", padding: "10px", borderRadius: "10px" }}>

                                <div key={`inline-radio-edit`} className="mb-3">
                                    <Form.Check
                                        defaultChecked
                                        inline
                                        ref={formImgChoiceEditRef}
                                        label="Edit"
                                        name="imgChoice"
                                        type="radio"
                                        onChange={e => setImageChoiceVariation(!e.target.checked)}
                                        disabled={apiWaiting}
                                        id={`inline-radio-1`}
                                    />
                                    <Form.Check
                                        inline
                                        ref={formImgChoiceVariationRef}
                                        label="Variation"
                                        name="imgChoice"
                                        type="radio"
                                        onChange={e => setImageChoiceVariation(e.target.checked)}
                                        disabled={apiWaiting}
                                        id={`inline-radio-2`}
                                    />
                                </div>

                                {
                                    !imageChoiceVariation &&
                                    <Form.Group className="mb-3" controlId="formImgEditDesc">
                                        <Form.Label>Image Edit Description</Form.Label>
                                        <Form.Control disabled={apiWaiting} name="prompt" ref={formImgEditDescRef} required type="text" />
                                        <Form.Text className="text-muted">
                                            A text description of the desired image(s). The maximum length is 1000 characters.
                                        </Form.Text>
                                    </Form.Group>
                                }

                                <div key={`inline-radio-input`} className="mb-3">
                                    <Form.Check
                                        defaultChecked
                                        inline
                                        ref={formInputChoiceFileRef}
                                        label="File"
                                        name="inputChoice"
                                        type="radio"
                                        onChange={e => setInputType(!e.target.checked)}
                                        disabled={apiWaiting}
                                        id={`inline-radio-1`}
                                    />
                                    <Form.Check
                                        inline
                                        ref={formInputChoiceURLRef}
                                        label="URL"
                                        name="inputChoice"
                                        type="radio"
                                        onChange={e => setInputType(e.target.checked)}
                                        disabled={apiWaiting}
                                        id={`inline-radio-2`}
                                    />
                                </div>

                                {
                                    !inputType &&
                                    <Form.Group className="mb-3" controlId="formImgFile">
                                        <Form.Label>Image File</Form.Label>
                                        <Form.Control
                                            onChange={e => handleImgFile(e)}
                                            disabled={apiWaiting}
                                            name="image"
                                            ref={formImgFileRef}
                                            required
                                            type="file" />
                                        <Form.Text className="text-muted">
                                            Must be a valid PNG file, less than 4MB, and square.
                                        </Form.Text>
                                    </Form.Group>
                                }

                                {
                                    inputType &&
                                    <Form.Group className="mb-3" controlId="formImgURL">
                                        <Form.Label>Image URL</Form.Label>
                                        <Form.Control
                                            disabled={apiWaiting}
                                            name="imageURL"
                                            ref={formImgURLRef}
                                            required
                                            type="text" />
                                        <Form.Text className="text-muted">
                                            If you don't have URL of your own, you can generate the url from the first tab!
                                        </Form.Text>
                                    </Form.Group>
                                }

                                <Form.Group className="mb-3" controlId="formNumImgEdit">
                                    <Form.Label>Number of Images</Form.Label>
                                    <Form.Control
                                        disabled={apiWaiting}
                                        name="formNumImgEdit"
                                        ref={formNumImgEditRef}
                                        required
                                        type="number" />
                                    <Form.Text className="text-muted">
                                        Must be between 1 and 10.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formImgEditSize">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Select name="formImgEditSize" disabled={apiWaiting} ref={formImgEditSizeRef} required aria-label="Default select size">
                                        <option value="1024x1024">1024 x 1024</option>
                                        <option value="512x512">512 x 512</option>
                                        <option value="256x256">256 x 256</option>
                                    </Form.Select>
                                </Form.Group>

                                <Button className="me-3" variant="secondary" onClick={handleReset} disabled={apiWaiting}>
                                    Reset
                                </Button>
                                {
                                    !apiWaiting &&
                                    <Button variant="primary" type="submit">
                                        Edit by AI
                                    </Button>
                                }
                                {
                                    apiWaiting &&
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Editing...
                                    </Button>
                                }
                            </Form>
                        </Tab>
                    </Tabs>
                </Container>
            </div >
            {
                apiResponseMessage &&
                <div className='projects-bg'>
                    <Container>
                        {
                            apiResponseMessage.map((data, idx) => (
                                <img key={idx} style={{ width: '100%', marginTop: '2rem' }} alt={`OpenAI generated graphics ${idx}`} src={data.url} />
                            ))
                        }
                    </Container>
                    <div className='text-center font-cherry-bomb mt-2 mb-2'>
                        ~~The End~~
                    </div>
                    <br></br>
                </div>
            }
        </>
    )
}

export default OpenAI;