import './Blogs.css';
import './BlogsLoader.css';
import { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { ReactMarkdownWithPlugins } from "./../shared/ReactMarkdownWithPlugins.js";
import { ContentEdit } from "./../shared/ContentEdit.js";
import { Preview } from "./../shared/Preview.js";
import { getLastUpdatedByRelative, getLastUpdatedByAbsolute } from "../shared/ContentUtility.js";
import Badge from 'react-bootstrap/Badge';

function BlogDetails() {
    const navigate = useNavigate();

    // this block of code handles screen size width to reset picture size
    const [currWidth, setCurrWidth] = useState(window.innerWidth);
    function handleResize() {
        setCurrWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    ///////////////////////////////////////////////

    // this block of code handles two: set blog & set author
    const [theBlogAuthor, setTheBlogAuthor] = useState(null);
    const [theBlog, setTheBlog] = useState(null);
    const [theBlogLastUpdated, setTheBlogLastUpdated] = useState(null);
    const [theBlogLastUpdatedSwitch, setTheBlogLastUpdatedSwitch] = useState(true);
    const location = useLocation();
    useEffect(() => {
        let previousState = location.state;
        if (previousState) {
            let data = previousState.data;
            if (data && data.id) {
                let sessionBlog = sessionStorage.getItem(data.id);
                let jsonBlog = JSON.parse(sessionBlog);
                if (jsonBlog && jsonBlog._ts > data._ts) {
                    setTheBlog(jsonBlog);
                    setTheBlogLastUpdated(jsonBlog._ts);
                } else {
                    setTheBlog(data);
                    setTheBlogLastUpdated(previousState.lastUpdated);
                }

                let currUserId = previousState.currUserId;
                if (currUserId) {
                    fetch(process.env.REACT_APP_CONTENT_AUTHOR_API_URL + `?id=${data.id}&user=${currUserId}`)
                        .then(response => {
                            if (response.ok) {
                                response.text().then(value => {
                                    let blogOwnerFound = JSON.parse(value);
                                    if (blogOwnerFound && blogOwnerFound[0] && blogOwnerFound[0].id) {
                                        if (blogOwnerFound[0].id === data.id) {
                                            setTheBlogAuthor(true);

                                            // Workaround for an edit submission
                                            previousState.data = null;
                                        }
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            }
        } else {
            navigate(window.location.pathname + '/..', { replace: true });
        }
    }, [location.state, navigate, theBlogLastUpdatedSwitch]);
    ///////////////////////////////////////////////

    // this block of code handles editing content
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = () => setEditMode(!editMode);
    const [showPreview, setShowPreview] = useState(false);
    const handlePreviewShow = () => setShowPreview(true);
    const handlePreviewClose = () => setShowPreview(false);
    const [apiWaiting, setAPIWaiting] = useState(false);
    const [apiAlert, setAPIAlert] = useState(false);
    const [apiAlertMessage, setAPIAlertMessage] = useState(null);
    const [apiSuccess, setAPISuccess] = useState(false);
    const formContentEditRef = useRef();
    const handleEditSubmit = (event) => {
        event.preventDefault();

        if (theBlog.formContent) {
            theBlog.formContent = formContentEditRef.current.value;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(theBlog)
        };

        setAPIWaiting(true);

        theBlog._ts = Math.floor(new Date().getTime() / 1000); // javascript epoch has additional 3 digits at the end
        sessionStorage.setItem(theBlog.id, JSON.stringify(theBlog)); // just in case user refreshes the browser
        fetch(process.env.REACT_APP_CONTENT_EDIT_API_URL, requestOptions)
            .then(response => {
                if (response.ok) {
                    setAPISuccess(true);
                    setAPIAlert(false);

                    // refresh the page after a commit
                    setTheBlog(theBlog);
                    setEditMode(false);
                    setTheBlogLastUpdated(theBlog._ts);
                } else {
                    setAPIAlert(true);
                }
                setAPIWaiting(false);
            })
            .catch(error => {
                setAPIAlertMessage(error.toString());
                setAPIAlert(true);
                setAPIWaiting(false);
            });
    }
    ///////////////////////////////////////////////

    // this block of code handles blog deletion
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleDeleteShow = () => setShowDeleteModal(true);
    const handleDelete = (event) => {
        event?.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(theBlog)
        };

        fetch(process.env.REACT_APP_CONTENT_DELETE_API_URL, requestOptions)
            .then(response => {
                if (response.ok) {
                    console.log('Blog deleted successfully! Navigating back now...');
                    navigate(window.location.pathname + '/..', { replace: true });
                } else {
                    console.log(`It was not able to delete. It returned ${response.status}.`);
                }
            });
    }
    ///////////////////////////////////////////////

    return (
        <>
            {
                theBlog &&
                <div className="blog-details-bg">
                    <img alt="Visual art based on current width of the screen" src={`https://picsum.photos/${currWidth}/350`} />
                    <Container style={{ marginTop: "1rem", marginBottom: "1rem", minHeight: "60%" }}>
                        {
                            apiAlert &&
                            <Alert variant="danger" onClose={() => setAPIAlert(false)} dismissible style={{ borderRadius: '20px' }}>
                                <Alert.Heading>Oh snap! Something's not right...</Alert.Heading>
                                {
                                    apiAlert &&
                                    <>
                                        {
                                            !apiAlertMessage &&
                                            <>
                                                <p>
                                                    Cosmos API failed. Please try again later.
                                                </p>
                                            </>
                                        }
                                        {
                                            apiAlertMessage &&
                                            <>
                                                <p>
                                                    {apiAlertMessage}
                                                </p>
                                            </>
                                        }
                                    </>
                                }
                            </Alert>
                        }
                        {
                            apiSuccess &&
                            <Alert variant="success" onClose={() => setAPISuccess(false)} dismissible style={{ borderRadius: '20px' }}>
                                <Alert.Heading>Saved!</Alert.Heading>
                            </Alert>
                        }
                        <Link to=".." relative="path">
                            &laquo; Go back
                        </Link>
                        <div className="text-center">
                            <h1>{theBlog.formTitle}</h1>
                            <div className='mb-3'>
                                {
                                    theBlog?.formTags?.map((tag, tagIdx) => (
                                        <Badge
                                            className="me-2"
                                            key={`tag-${tagIdx}`}
                                            pill
                                            bg="secondary">
                                            {tag.formTag}
                                        </Badge>
                                    ))
                                }
                            </div>
                            <Row>
                                {
                                    theBlogLastUpdated &&
                                    <small className="text-muted" >
                                        {
                                            theBlogLastUpdatedSwitch &&
                                            <span onClick={(event) => { event.preventDefault(); setTheBlogLastUpdatedSwitch(!theBlogLastUpdatedSwitch) }}>{getLastUpdatedByRelative(theBlogLastUpdated)}</span>
                                        }
                                        {
                                            !theBlogLastUpdatedSwitch &&
                                            <span onClick={(event) => { event.preventDefault(); setTheBlogLastUpdatedSwitch(!theBlogLastUpdatedSwitch) }}>{getLastUpdatedByAbsolute(theBlogLastUpdated)}</span>
                                        }
                                    </small>
                                }
                            </Row>
                        </div>
                        {
                            !editMode &&
                            <>
                                {
                                    theBlogAuthor &&
                                    <>
                                        <div className="text-center">
                                            <Button variant="link" onClick={toggleEditMode} style={{ marginRight: '1vw' }}>edit</Button>
                                            <Button variant="link" onClick={handleDeleteShow}>delete</Button>
                                        </div>
                                    </>
                                }
                                <div style={{ marginTop: "3rem" }} className="blog-details-text">
                                    <ReactMarkdownWithPlugins content={theBlog.formContent}></ReactMarkdownWithPlugins>
                                </div>
                            </>
                        }
                        {
                            editMode &&
                            <>
                                <div className="text-center">
                                    <Button variant="link" onClick={toggleEditMode}>cancel</Button>
                                </div>
                                <ContentEdit
                                    apiWaiting={apiWaiting}
                                    aboutContent={theBlog.formContent}
                                    formContentRef={formContentEditRef}
                                    handleShow={handlePreviewShow}
                                    handleSubmit={handleEditSubmit} />
                                {
                                    formContentEditRef && formContentEditRef.current &&
                                    <Preview
                                        preview={{ show: showPreview, content: formContentEditRef.current.value }}
                                        handleCallBack={handlePreviewClose} />
                                }
                            </>
                        }
                    </Container>
                    <div className="footer">
                        <div className="text-muted">
                            <div>
                                <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link> | <Link to="/blogs" style={{ textDecoration: 'none', color: 'black' }}>Blogs</Link> | <Link to="/contact" style={{ textDecoration: 'none', color: 'black' }}>Contact</Link>
                            </div>
                            <div>&copy; 2023 JustinKim.dev</div>
                        </div>
                    </div>
                </div>
            }
            {
                !theBlog &&
                <div className="blog-details-loading-bg">
                    <Row xs={1} md={2} lg={2} className="g-2">
                        <Col>
                            <Link to=".." relative="path">
                                &laquo; Go back
                            </Link>
                        </Col>
                        <Col>
                            <div className="font-cherry-bomb">
                                Currently loading...
                            </div>
                        </Col>
                    </Row>

                    <div className="pl">
                        <div className="pl__outer-ring"></div>
                        <div className="pl__inner-ring"></div>
                        <div className="pl__track-cover"></div>
                        <div className="pl__ball">
                            <div className="pl__ball-texture"></div>
                            <div className="pl__ball-outer-shadow"></div>
                            <div className="pl__ball-inner-shadow"></div>
                            <div className="pl__ball-side-shadows"></div>
                        </div>
                    </div>
                </div>
            }
            {
                <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Once deleted, it cannot be retrieved. Feel free to save the content elsewhere before you proceed.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleDeleteClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default BlogDetails;