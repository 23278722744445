import './Projects.css';
import './ProjectsBGAnimation.css';
import NavigationBar from "../navbar/NavigationBar";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
// import ListGroup from 'react-bootstrap/ListGroup';

function Projects() {

    return (
        <>
            <div className="projects-bg">
                <div className="night">
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                    <div className="shooting_star"></div>
                </div>
                <NavigationBar></NavigationBar>
                <Container style={{ marginTop: "1rem", marginBottom: "1rem", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card className="text-center focus-card " style={{ width: '18rem' }}>
                        {/* Moved Nav.Link inside the Card element, as sometimes <a> anchor goes outside of the Card element */}
                        <Nav.Link as={Link} to="./openai">
                            <Card.Img variant="top" src="./assets/images/projects/ChatGPT.jpg" />
                            <Card.Body>
                                <Card.Text>Integration with most popular project of the century - ChatGPT</Card.Text>
                            </Card.Body>
                        </Nav.Link>
                    </Card>
                </Container>
            </div>
        </>
    );
}

export default Projects;